.login-page-header{
    text-align: left;
    font-weight: bold;
    font-size: 22px;
    padding-top: 1px;
}

.login-card-header{
    font-weight: bold;
}

.login-card-content{
    font-size: 16px;
}

.login-build-number{
    text-align: right;
    font-weight: bold;
}
.home-page-header{
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  padding-top: 2px;
}

.intro-text{
  font-weight: bold;
  text-align: center;
}

.welcome-card-header{
  text-align: center;
  font-weight: bold;
}

.welcome-card-text{
  font-size: 22px;
}

.mario-yahtzee-card-header{
  font-weight: bold;
}

.standard-yahtzee-card-header{
  font-weight: bold;
}

.settings-card-header{
  font-weight: bold;
}

.exit-card-header{
  font-weight: bold;
}
.settings-page-header{
  text-align: center;
  font-weight: bold;
}

.build-number{
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.settings-header{
  font-weight: bold;
}

.settings-content{
  font-size: 16px;
}

.settings-content-bold{
  font-weight: bold;
  font-size: 16px;
}

.selection-setting{
  font-weight: bold;
  font-size: 16px;
}

.changelog-setting{
  font-size: 16px;
}

ion-alert{
  color: var(--ion-text-color);
}

ion-select{
  color: var(--ion-color-success);
  --placeholder-opacity: 1;
  --placeholder-color: var(--ion-color-medium);
  font-weight: bold;
  font-size: 22px;
}

ion-select::part(icon) {
  color: var(--ion-text-color);
}
.records-page-header{
    text-align: center;
    font-weight: bold;
}

.records-header{
    font-weight: bold;
}

.records{
    font-size: 16px;
}

.score-records{
    font-weight: bold;
    font-size: 20px;
}
.yahtzee-score-card-page-header{
    text-align: center;
    font-weight: bold;
}

.settings-header{
    font-weight: bold;
}

.score-card-heading-text{
    font-weight: bold;
    font-size: 22px;
}

.score-card-info-text{
    font-weight: bold;
    font-size: 22px;
}

ion-alert{
    color: var(--ion-text-color);
}

ion-select{
    color: var(--ion-color-success);
    --placeholder-opacity: 1;
    --placeholder-color: var(--ion-color-medium);
    font-weight: bold;
    font-size: 22px;
}

ion-select::part(icon) {
    color: var(--ion-text-color);
}
